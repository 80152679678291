var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sell"},[_c('div',{staticClass:"bg"}),_c('div',{style:({
      margin: '0 auto',
      height: '50px',
      maxWidth: '960px',
    })},[_vm._m(0)]),_c('div',{staticClass:"text",style:({
      margin: '0 auto',
      maxWidth: '960px',
    })},[_c('div',[_c('van-row',_vm._l((_vm.items),function(item){return _c('van-col',{key:item._id,attrs:{"span":"6"}},[_c('div',{staticStyle:{"padding":"10px","position":"relative"},on:{"click":function($event){return _vm.showbuyy(
                item.sellprice,
                item.tokenid,
                item.uid,
                item.title,
                item.img,
                item.picpath,
                item.price,
                item.author,
                item.signature
              )}}},[_c('van-tag',{directives:[{name:"show",rawName:"v-show",value:(item.uid === _vm.uid),expression:"item.uid === uid"}],staticClass:"my",attrs:{"mark":"","type":"primary","plain":""}},[_vm._v("my")]),_c('van-tag',{directives:[{name:"show",rawName:"v-show",value:(item.flag === 10),expression:"item.flag === 10"}],staticClass:"my",attrs:{"mark":"","type":"primary","plain":""}},[_vm._v("Official certification")]),_c('div',{staticClass:"bizhi"},[_vm._v(" value "+_vm._s(_vm._f("price")(item.price)))]),_c('van-image',{attrs:{"width":"100%","height":"200","src":'http://www.artii.top/nftpic_v1/' +
                item.picpath +
                '/' +
                item.img.replace('/home/xvx/www/', '')}}),_c('div',{staticClass:"tt"},[_c('h2',{staticStyle:{"font-size":"18px","padding":"0","height":"24px","margin":"5px 0 0","overflow":"hidden","text-overflow":"ellipsis","white-space":"nowrap"}},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('div',{staticClass:"qipai"},[_vm._v(" 售价"),_c('span',[_c('b',[_vm._v(_vm._s(_vm._f("price")(item.sellprice)))]),_c('img',{attrs:{"src":"/images/qian.png"}})])]),_c('div',{staticClass:"zhuangtai"},[_vm._v("Sales status"),_c('span',[_vm._v("On sale")])])])],1)])}),1),_c('van-overlay',{attrs:{"show":_vm.show2,"z-index":"10000000"}},[_c('div',{staticClass:"wrapper",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"block1"},[_c('van-icon',{attrs:{"name":"smile-o","size":"32"}}),_c('br'),_vm._v(" After confirmation, please wait patiently for automatic jump and do not refresh ")],1)])])],1)]),_c('div',{style:({
      margin: '0 auto',
      height: '50px',
      maxWidth: '960px',
    })},[_vm._m(1)]),_c('van-overlay',{attrs:{"z-index":"10000","show":_vm.showbuy}},[_c('div',{staticClass:"wrapper",on:{"click":_vm.closes}},[_c('div',{staticClass:"block",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"main2"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.currtitle))]),_c('div',{staticClass:"left"},[_c('img',{attrs:{"src":_vm.thumb}})]),_c('div',{staticClass:"right"},[_c('div',{staticClass:"yikoujia2"},[_c('span',{staticStyle:{"margin":"0 80px 0 67px"}},[_vm._v("signature:")]),_vm._v(" "+_vm._s(_vm.signature)+" "),_c('br'),_c('span',{staticStyle:{"margin":"0 80px 0 67px"}},[_vm._v(" value:")]),_vm._v(" "+_vm._s(_vm.currbizhi)+" "),_c('br'),_c('span',{staticStyle:{"margin":"0 80px 0 50px"}},[_vm._v("Caster:")]),_vm._v(" "+_vm._s(_vm.zhuzaozhe)+" "),_c('br'),_c('span',{staticStyle:{"margin":"0 80px 0 67px"}},[_vm._v("number:")]),_vm._v(" "+_vm._s(_vm.tid)+" "),_c('br'),_c('span',{staticStyle:{"margin":"0 80px 0 50px"}},[_vm._v("price:")]),_c('span',[_vm._v(_vm._s(_vm.price))])]),_c('div',{staticClass:"btn2"},[_c('van-button',{staticClass:"mybtn",attrs:{"type":"info","color":"#00a0e9","plain":""},on:{"click":function($event){return _vm.buyy()}}},[_vm._v("Buy")]),_c('div',{staticClass:"clear"})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"fenxiang"},[_c('img',{attrs:{"src":"/images/fenxiang.png"}}),_c('span',[_vm._v("分享到其他凭平台")]),_c('div',{staticClass:"clear"})])]),_c('div',{staticClass:"clear"})])])])]),_c('div',{staticClass:"mycopy"},[_vm._v(" Copyright Artii 2021 ")])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mytitle"},[_c('label',[_vm._v("All sold")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mytitle"},[_c('label',[_vm._v("Latest sale")]),_c('a',[_vm._v("view all")])])}]

export { render, staticRenderFns }